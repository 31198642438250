<template>
  <div class="product">
    <div class="aijc">
      <div class="aijc_box">
        <p>AI监测</p>
        <p>实时为监督者提供精准的数据研判</p>
        <p>AI监测云服务 | Web及大屏展示 | 小程序监测 | 监测场景</p>
        <div class="btn">在线咨询</div>
      </div>
    </div>
    <div class="hxld">
      <p>核心亮点</p>
      <div class="hxld_box">
        <img class="img" id="img" src="@/assets/images/product/aijc/hxld.png" />
        <div class="hxld_box_content" id="title">
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/aijc/hxld_icon1.png"
            />
            <p class="first_line">AI监测云服务</p>
            <p class="second_line">
              包括数据服务、文件服务、数据报表、地图显示等服务功能
              支持多用户层级管理、语音告警提示、远程视频播放等功能
            </p>
          </div>
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/aijc/hxld_icon2.png"
            />
            <p class="first_line">Web及大屏展示</p>
            <p class="second_line">
              AI监测管理平台支持大屏展示、告警报表及详情展示，
              可以自定义展示报表、自定义LOGO
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="aisf">
      <div class="aisf_box_item">
        <div class="aisf_box_content" id="title2">
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/aijc/aisf_icon1.png"
            />
            <p class="first_line">AI算法</p>
            <p class="second_line">
              数据积累强大，高召回率，低误报率，<br />自我学习，可根据不同需求调用不同算法
            </p>
          </div>
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/aijc/aisf_icon2.png"
            />
            <p class="first_line">AI巡检</p>
            <p class="second_line">
              巡检内容标准化、电子化、智能化。巡检人员用<br />微信小程序完成AI巡检，管理者在监控中心掌握全局动态情况
            </p>
          </div>
        </div>
        <img
          class="img"
          id="img2"
          src="@/assets/images/product/aijc/aisf.png"
        />
      </div>
    </div>
    <div class="xcxjc">
      <div class="xcxjc_box">
        <img
          class="img"
          id="img3"
          src="@/assets/images/product/aijc/xcxjc.png"
        />
        <div class="xcxjc_box_content" id="title3">
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/aijc/xcxjc_icon1.png"
            />
            <p class="first_line">小程序监测</p>
            <p class="second_line">
              AI监测微信小程序提供移动端的告警及巡检即时处理，<br />实时记录现场数据，以供后续总结、查询
            </p>
          </div>
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/aijc/xcxjc_icon2.png"
            />
            <p class="first_line">监测场景</p>
            <p class="second_line">
              可实现以下监测场景：<br />火苗烟雾、物体异动、抽烟行为、安全帽佩戴、人员出入、<br />人流统计、边界跨越、人群聚集、专人值守、人员逗留、<br />车流统计、车辆乱停、车位状态、面板老化等
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data () {
    return {

    }
  },
  mounted () {
    $(window).scroll(this.scrollBar)
  },
  methods: {
    scrollBar () {
      const h = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      var aisfH = $('.aisf').offset().top
      var xcxjcH = $('.xcxjc').offset().top
      if (h > aisfH - 500) {
        $('#img2').addClass('animation')
      }
      if (h > xcxjcH - 500) {
        $('#img3').addClass('animation')
      }
    }
  },
  destroyed () {
    $(window).unbind('scroll', this.scrollBar)
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
</style>
